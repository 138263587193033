img {
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
}

@font-face {
  font-family: "Gotham-Bold";
  src: url("fonts/Gotham-Bold.eot");
  src: url("fonts/Gotham-Bold.eot?#iefix") format("embedded-opentype"),
    url("fonts/Gotham-Medium.otf") format("opentype"),
    url("fonts/Gotham-Medium.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  /* 
  Unsures text remains visible during font load
  for more detail;  
  https://developers.google.com/web/updates/2016/02/font-display?utm_source=lighthouse&utm_medium=cli
  */
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: url("fonts/Gotham.eot");
  src: url("fonts/Gotham.eot?#iefix") format("embedded-opentype"),
    url("fonts/Gotham-Book.otf") format("opentype"), url("fonts/Gotham-Book.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  /* 
  Unsures text remains visible during font load
  for more detail;  
  https://developers.google.com/web/updates/2016/02/font-display?utm_source=lighthouse&utm_medium=cli
  */
  font-display: swap; 
}

@media only screen and (min-width: 767px) {
  .bigScreenMenu {
    display: flex !important;
  }
}

.menuTogglespan {
  display: block;
  width: 24px;
  height: 3px;
  margin-bottom: 5px;
  position: relative;
  background: rgb(0, 130, 60);
  border-radius: 3px;
}
/* MANN HUMMEL CUSTOM ELEMENTS */

.ui.grey.button {
  box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.15) inset !important;
  -moz-box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.15) inset !important;
  -webkit-box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.15) inset !important;
  -ms-box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.15) inset !important;
  -o-box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.15) inset !important;
}

.ui.green.table {
  border-top: 0.2em solid rgb(40, 121, 70);
}

.ui.labeled.input > .label {
  font-weight: 600 !important;
}

.ui.form .field > label {
  font-weight: 600 !important;
}

.ui.label,
.ui.labels .label {
  justify-content: center;
  display: flex;
  align-items: center;
}

/* MANN HUMMEL CUSTOM ELEMENTS */

*:not(i) {
  font-family: "Gotham", "Gotham-Book", "Open Sans" !important;
}

body,
html {
  padding: 0;
  margin: 0;
}

body {
  padding-top: 73px;
}

.blurring {
  padding-top: 14px;
}

.bg {
  outline: none !important;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 40px);
  background: rgb(250, 250, 250);
  background: linear-gradient(
    90deg,
    rgba(250, 250, 250, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(250, 250, 250, 1) 100%
  );
}

#svg-map path {
  cursor: pointer;
}

.svg-map {
  max-width: 1007px !important;
  margin: 0 auto;
  height: 700px;
  text-align: center;
}

.svg-map svg {
  width: 100%;
  height: auto;
}

.svg-hidden {
  opacity: 0;
}

.svg-shadow {
  -webkit-filter: drop-shadow(-3px 2px 2px rgba(136, 136, 136, 0.5));
  filter: drop-shadow(-3px 2px 2px rgba(136, 136, 136, 0.5));
}

.fadein {
  opacity: 1;
  transition: all 2s ease-in;
  -moz-transition: all 2s ease-in;
  -o-transition: all 2s ease-in;
  -ms-transition: all 2s ease-in;
  transition: all 2s ease-in;
}

.fadeout {
  opacity: 0;
  transition: all 2s ease-out;
  -moz-transition: all 2s ease-out;
  -o-transition: all 2s ease-out;
  -ms-transition: all 2s ease-out;
  transition: all 2s ease-out;
}

.ui.secondary.menu .active.item {
  color: rgb(58, 132, 73) !important;
  background-color: transparent;
}

.highcharts-pie-series .highcharts-point {
  stroke: #ede;
  stroke-width: 2px;
}

.highcharts-pie-series .highcharts-data-label-connector {
  stroke: silver;
  stroke-dasharray: 2, 2;
  stroke-width: 2px;
}

.custom-header {
  background-color: rgba(255, 255, 255, 0.9);
  border-bottom: 1px solid #dadada;
  position: fixed;
  z-index: 1000;
  right: 0;
  left: 0;
  top: 0;
}

.header-image {
  fill: #3a8449;
}

::-webkit-scrollbar {
  width: 0px; /* remove scrollbar space */
  background: transparent; /* optional: just make scrollbar invisible */
}

.no-pointer-event {
  pointer-events: none;
}

.custom-toggle {
  cursor: pointer;
  height: 38px;
  line-height: 37px;
  width: 65px;
  border: 1.5px solid rgb(2, 138, 195);
  font-weight: 600;
  font-size: 14px;
  text-align: center;
}

.\.progressbar-container svg {
  border-radius: 3px !important;
}

.new-input-container {
  border: 1.5px solid rgb(58, 132, 73);
  border-radius: 5px;
  position: relative;
  padding: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-top: 40px;
  margin-left: 10px;
  margin-right: 10px;
}

.new-input-container h2 {
  top: -17px;
  position: absolute;
  background-color: white;
  color: rgb(58, 132, 73);
}

.ui.form .disabled.field,
.ui.form .disabled.fields .field,
.ui.form .field :disabled {
  opacity: 1 !important;
}




input:active,
input:focus,
textarea:active,
textarea:focus,
select:active,
select:focus,
.ui.dropdown:active,
.ui.dropdown:focus,
.ui.dropdown.active ,
.ui .menu.visible {
  border-color: rgb(58, 132, 73) !important;
}

 input:focus:checked ~ .box:before,
input:focus:checked ~ label:before{
  border-color: rgb(58, 132, 73) !important;
}

.box:before,
label:before {
  border-color: rgb(58, 132, 73) !important;
}

input:checked ~ .box:before,
input:checked ~ label:before  {
  border-color: rgb(58, 132, 73) !important;
} 

#map-outlet-name-select input:active,
#map-outlet-name-select input:focus,
#map-outlet-name-select textarea:active,
#map-outlet-name-select textarea:focus,
#map-outlet-name-select select:active,
#map-outlet-name-select select:focus,
#map-outlet-name-select.ui.dropdown:active,
#map-outlet-name-select.ui.dropdown:focus,
#map-outlet-name-select.ui.dropdown.active,
#map-outlet-name-select.ui .menu.visible {
  border-color: #226b80 !important;
}

#map-outlet-name-select  input:focus:checked ~ .box:before,
#map-outlet-name-select  input:focus:checked ~ label:before {
  border-color: #226b80 !important;
}

#map-outlet-name-select  .box:before,
#map-outlet-name-select  label:before {
  border-color: #226b80 !important;
}

#map-outlet-name-select  input:checked ~ .box:before,
#map-outlet-name-select  input:checked ~ label:before {
  border-color: #226b80 !important;
}




.selected-perc-btn {
  background-color: rgb(255, 115, 0) !important;
}

.width-100 {
  width: 100%;
}

.filepond--drop-label label {
  font-size: 14px;
  line-height: inherit;
}

.filepond--label-action {
  font-size: 14px;
  font-weight: 800;
  color: rgb(58, 132, 73) !important;
}

.suir-calendar {
  cursor: pointer !important;
}

.custom-error-label {
  color: #db2828;
  margin-top: 5px;
  font-size: 13px;
  text-align: center;
}

.ui.labeled.input:not([class*="corner labeled"]) .label:first-child {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.filepond--root {
  margin-bottom: 0;
}

.default-page-padding {
  padding-top: 40px !important;
}

.ui.dropdown {
  font-weight: 300;
}

.header.item {
  background-color: transparent !important;
}

.tool-tip-label {
  display: block;
  color: rgba(0, 0, 0, 0.7);
  background-color: white;
  margin-left: 7;
}

.loader {
  top: 200px !important;
}

.dimmed.dimmable > .ui.animating.dimmer,
.dimmed.dimmable > .ui.visible.dimmer,
.ui.active.dimmer {
  display: flex !important;
  align-items: center !important;
}

.ui.modal,
.ui.active.modal {
  margin: 0 auto !important;
  top: auto !important;
  left: auto !important;
  transform-origin: center !important;
  transition: all ease 0.5s;
}

.visible.transition {
  margin-top: auto !important;
  display: inline-block !important;
  position: relative;
  top: 20%;
}

.const-table {
  border: 0 !important;
}

.const-table thead tr:first-child > th {
  position: sticky !important;
  top: 0;
  z-index: 2;
}

.rbc-today {
  background-color: rgba(0, 130, 60, 0.15) !important;
}

.rbc-btn-group button {
  box-shadow: none !important;
  outline: none !important;
  border: 1px solid #ccc !important;
}

.rbc-time-view {
  border: none;
}

.rbc-time-content {
  display: none;
}

.rbc-time-header {
  border: 1px solid #ddd;
}

.rbc-agenda-date-cell {
  background-color: rgba(256, 256, 256, 1);
}

.rbc-agenda-empty {
  text-align: center;
}

.ui.dropdown > .text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
  display: block;
}

.objective-text .progressbar-text {
  font-size: 13px;
}

.ui.form .field > label {
  font-size: 14px !important;
}

#region-gauteng:hover {
  transform: scale(3.2);
}
#region-gauteng {
  transition: all 0.8s;
  transition-timing-function: cubic-bezier(1.87,2,1.6,1.19);
  transform-origin: 50% 50%;
  transform-box: fill-box
}
